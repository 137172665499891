import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Irving({ styles, ...props }) {
  const Elements = useComponents()
  const checkboxes_color = styles.forElement("right_text").color

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div className="formkit-wrapper">
        <div className="formkit-container">
          <div
            className="formkit-content-container"
            style={{
              backgroundColor: styles.forElement("background").backgroundColor
            }}
          >
            <div
              style={styles.forElement("background")}
              className="formkit-bg-image"
            />
            <aside className="formkit-left-column">
              <Elements.Image
                className="formkit-form-image"
                name="image"
                defaults={{
                  src:
                    "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/7jmiuB8AZ9SGqP9fzP2TYa"
                }}
              />
              <Elements.Heading
                className="formkit-heading"
                name="heading"
                tag="h2"
                defaults={{
                  content: "Featuring the hit singles"
                }}
              />
              <Elements.Region name="left" className="formkit-content">
                <Elements.Content
                  defaults={{
                    content:
                      "<ul><li>To you, and you, and you.</li><li>Goodbye, for now.</li><li>Are you really going?​</li></ul>"
                  }}
                />
              </Elements.Region>
            </aside>
          </div>
          <div
            className="formkit-form-container"
            style={styles.forElement("form_background")}
          >
            <div>
              <header>
                <Elements.Heading
                  className="formkit-form-heading"
                  name="form_heading"
                  defaults={{
                    content:
                      "<strong>Adieu,</strong><br/>To you, and you, and you"
                  }}
                />
                <Elements.Content
                  className="formkit-subheader"
                  name="subheader"
                  defaults={{
                    content: "The upcoming album from Adieu."
                  }}
                />
              </header>
              <main>
                <Elements.Region name="right" className="formkit-form-content">
                  <Elements.Content
                    defaults={{
                      content:
                        "Spoken word jazz duo, Adieu, will soon be releasing their newest album, Adieu: To you, and you, and you. Sign up to download the title single, and get more album insights!"
                    }}
                  />
                </Elements.Region>
                <Elements.Form>
                  <Elements.Errors />
                  <Elements.CustomFields
                    style={{
                      color: checkboxes_color
                    }}
                  >
                    <Elements.AddFieldButton />
                    <Elements.Button
                      name="submit"
                      group="button"
                      defaults={{ content: "Sign up & download" }}
                    />
                  </Elements.CustomFields>
                </Elements.Form>
                <Elements.Content
                  className="formkit-disclaimer"
                  name="disclaimer"
                  defaults={{
                    content: "We respect your privacy. Unsubscribe at any time."
                  }}
                />
              </main>
              <footer>
                <Elements.BuiltWith background="form_background" />
              </footer>
            </div>
          </div>
        </div>
        <div className="formkit-accent" style={styles.forElement("accent")} />
      </div>
    </Elements.LandingPage>
  )
}

export default createTemplate(Irving, { name: "Irving" })
